import '../../styles/Home.css';
import { useNavigate } from 'react-router-dom';
import ProductListItem from '../../components/Product/Other/ProductListItem';
import { useLayoutEffect, useState } from 'react';
import { IProductBase } from '../../models/product-base';
import Environment from '../../common/environment';
import Banner from '../../components/Home/Banner';
import ProductService from '../../services/product.service';
import { useScrollOnLoad } from '../../hooks/useScrollOnLoad';
import MetaTags from '../../components/Html/MetaTags';
import { SeoConfig } from '../../helpers/seo-config';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { BiSupport } from 'react-icons/bi';
import { AxiosAuthorized } from '../../common/axios';
import useUser from '../../hooks/useUser';
import { UserRole, WebImagePosition } from '../../common/enums';
import { IWebImageBase } from '../../models/web-images';
import WebImagesService from '../../services/webimages.service';
import { useDispatch } from 'react-redux';
import { loadWebImages } from '../../redux/reducers/webImagesReducer';
import { defaultUIImages } from '../../common/data';

const Home = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [bestSellers, setBestSellers] = useState<IProductBase[]>([]);
  const [discounted, setDiscounted] = useState<IProductBase[]>([]);
  const [webImages, setWebImages] = useState<IWebImageBase[]>(defaultUIImages);

  const user = useUser();

  useLayoutEffect(() => {
    if (user?.roleId === UserRole.Admin) {
      navigate('/admin/dashboard');
    }

    if (user?.roleId === UserRole.Employee) {
      navigate('/admin/products');
    }
  }, [user, navigate]);

  useScrollOnLoad();

  useLayoutEffect(() => {
    const loadData = async () => {
      const [totalCountData, imagesList] = await Promise.all([
        ProductService.loadTotalCount(),
        WebImagesService.uiList(window.innerWidth),
      ]);

      if (totalCountData?.data?.count > 0) {
        await getBestSellers();
      }

      if (imagesList?.data?.length > 0) {
        setWebImages(imagesList.data);
      }

      dispatch(loadWebImages(imagesList.data));
    };

    const getBestSellers = async () => {
      const count = window.innerWidth < 1180 ? 4 : 5;

      let url = `${Environment.api}/products/filter?sortBy=soldCount&sortDirection=desc`;
      url += `&pageNumber=0&resultsPerPage=${count}`;

      const { data } = await AxiosAuthorized.get(url);
      setBestSellers(data.products);

      const discountedUrl = `${Environment.api}/products/discounted?take=${count}`;
      const discounteData = await AxiosAuthorized.get(discountedUrl);
      setDiscounted(discounteData.data);
    };

    loadData();
  }, [dispatch]);

  return (
    <>
      <MetaTags data={SeoConfig.Home} />
      <div className='home-page'>
        <Banner images={webImages} />
        <div className='product-type'>
          {webImages.length > 0 && (
            <>
              <article className='product-type-data' onClick={() => navigate('/store?genders=0')}>
                <div className='img-wrapper'>
                  <img
                    src={
                      webImages.find((x) => x.imagePosition === WebImagePosition.MalePoster)?.url
                    }

                    alt='Obuća BIG - Muška obuća'
                  />
                </div>

                <h3>Muška obuća</h3>
              </article>
              <article className='product-type-data' onClick={() => navigate('/store?genders=1')}>
                <div className='img-wrapper'>
                  <img
                    src={
                      webImages.find((x) => x.imagePosition === WebImagePosition.FemalePoster)?.url
                    }
                    alt='Obuća BIG - Ženska obuća'
                  />
                </div>
                <h3>Ženska obuća</h3>
              </article>
              <article className='product-type-data' onClick={() => navigate('/store?genders=2')}>
                <div className='img-wrapper'>
                  <img
                    src={
                      webImages.find((x) => x.imagePosition === WebImagePosition.KidsPoster)?.url
                    }
                    alt='Obuća BIG - Dečija Obuća'
                  />
                </div>
                <h3>Dečija obuća</h3>
              </article>
            </>
          )}
        </div>
        <div className='posters-wrp'>
          <img
            src={webImages.find((x) => x.imagePosition === WebImagePosition.PosterMain)?.url}
            alt='Obuća BIG - Ženske patike'
            className='main-poster'
            loading='lazy'
            decoding='async'
          />
          <div className='posters'>
            <img
              src={webImages.find((x) => x.imagePosition === WebImagePosition.TopRightPoster)?.url}
              alt='Obuća BIG - Ženske čizme'
              className='secondary-poster'
              loading='lazy'
              decoding='async'
            />
            <img
              src={
                webImages.find((x) => x.imagePosition === WebImagePosition.BottomRightPoster)?.url
              }
              alt='Obuća BIG - Ženske sandale'
              className='secondary-poster'
              loading='lazy'
              decoding='async'
            />
          </div>
        </div>
        {discounted.length > 0 && (
          <div className='related-wrapper home-related-wrapper'>
            <div className='home-title-for-list'>
              <div className='line'></div>
              <h1 className='home-related-title'>NA POPUSTU</h1>
              <div className='line'></div>
            </div>
            <div className='related-products-mapping'>
              {discounted.map((x) => (
                <ProductListItem product={x} key={x.id} />
              ))}
            </div>
          </div>
        )}

        <div className='side-bar-images'>
          <div className='side-image'>
            <img
              src={webImages.find((x) => x.imagePosition === WebImagePosition.LeftPoster)?.url}
              alt='Obuća BIG Sniženje'
              className='side-image-tag'
              loading='lazy'
              decoding='async'
              onClick={() => navigate('/store?searchType=discount')}
            />
          </div>
          <div className='side-image'>
            <img
              src={webImages.find((x) => x.imagePosition === WebImagePosition.RightPoster)?.url}
              alt='Obuća BIG Modeli'
              loading='lazy'
              decoding='async'
              className='side-image-tag'
              onClick={() => navigate('/store?genders=1')}
            />
          </div>
        </div>
        {bestSellers.length > 0 && (
          <div className='related-wrapper home-related-wrapper'>
            <div className='home-title-for-list'>
              <div className='line'></div>
              <h1 className='home-related-title'>NAJPRODAVANIJE</h1>
              <div className='line'></div>
            </div>
            <div className='related-products-mapping products-bottom-margin'>
              {bestSellers.map((x) => (
                <ProductListItem product={x} key={x.id} />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className='services'>
        <div className='service'>
          <MdOutlineLocalShipping className='icon-service' />
          <p>
            Besplatna dostava za iznos preko <span style={{ color: '#fa0a32' }}>6000</span> RSD
          </p>
        </div>
        <div className='service'>
          <RiSecurePaymentLine className='icon-service' />
          <p>100% Sigurna kupovina putem shopa</p>
        </div>
        <div className='service'>
          <BiSupport className='icon-service' />
          <p>Ukoliko Vam je potrebna pomoć, pozovite nas</p>
        </div>
      </div>
    </>
  );
};

export default Home;
