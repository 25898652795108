import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MasterLayout from '../layout/MasterLayout';
import Home from '../pages/shared/Home';
import Checkout from '../pages/shopping/Checkout';
import NotFound from '../pages/shared/NotFound';
import SignIn from '../pages/auth/SignIn';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Register from '../pages/auth/Register';
import Account from '../pages/auth/Account';
import ProtectedRoute from './ProtectedRoute';
import ResetPassword from '../pages/auth/ResetPassword';
import Store from '../pages/shopping/Store';
import Product from '../pages/shopping/Product';
import ShoppingCart from '../pages/shopping/ShoppingCart';
import { UserRole } from '../common/enums';
import Products from '../pages/admin/products/Products';
import AddProduct from '../pages/admin/products/AddProduct';
import EditProduct from '../pages/admin/products/EditProduct';
import Users from '../pages/admin/users/Users';
import Orders from '../pages/admin/orders/Orders';
import Coupons from '../pages/admin/coupons/Coupons';
import Verify from '../pages/auth/Verify';
import Wishlist from '../pages/shopping/Wishlist';
import CheckOrderStatus from '../pages/shopping/CheckOrderStatus';
import Settings from '../pages/admin/settings/Settings';
import AddEmployee from '../pages/admin/users/AddEmployee';
import Dashboard from '../pages/admin/statistics/Dashboard';
import PrivacyAndPolicy from '../pages/shared/PrivacyAndPolicy';
import WebImages from '../pages/admin/webimages/WebImages';
import AddWebImage from '../pages/admin/webimages/AddWebImage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/shopping-cart' element={<ShoppingCart />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/account/verify/:token' element={<Verify />} />
          <Route path='/product/:id' element={<Product />} />
          <Route path='/register' element={<Register />} />
          <Route path='/store' element={<Store />} />
          <Route path='/privacy-and-policy' element={<PrivacyAndPolicy />} />
          <Route path='/check-order-status' element={<CheckOrderStatus />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
          <Route
            path='/account'
            element={
              <ProtectedRoute
                authorize={[UserRole.Buyer, UserRole.Admin, UserRole.Employee]}
                component={<Account />}
              />
            }
          />
          <Route
            path='/my-orders'
            element={<ProtectedRoute authorize={[UserRole.Buyer]} component={<Orders />} />}
          />
          <Route
            path='/wishlist'
            element={<ProtectedRoute authorize={[UserRole.Buyer]} component={<Wishlist />} />}
          />
          <Route
            path='/admin/dashboard'
            element={
              <ProtectedRoute
                authorize={[UserRole.Admin, UserRole.Employee]}
                component={<Dashboard />}
              />
            }
          />
          <Route
            path='/admin/products'
            element={
              <ProtectedRoute
                authorize={[UserRole.Admin, UserRole.Employee]}
                component={<Products />}
              />
            }
          />        
          <Route
            path='/admin/settings'
            element={<ProtectedRoute authorize={[UserRole.Admin]} component={<Settings />} />}
          />
          <Route
            path='/admin/coupons'
            element={<ProtectedRoute authorize={[UserRole.Admin]} component={<Coupons />} />}
          />
          <Route
            path='/admin/products/add-new'
            element={
              <ProtectedRoute
                authorize={[UserRole.Admin, UserRole.Employee]}
                component={<AddProduct />}
              />
            }
          />
          <Route
            path='/admin/products/edit/:id'
            element={
              <ProtectedRoute
                authorize={[UserRole.Admin, UserRole.Employee]}
                component={<EditProduct />}
              />
            }
          />
          <Route
            path='/admin/users'
            element={<ProtectedRoute authorize={[UserRole.Admin]} component={<Users />} />}
          />
          <Route
            path='/admin/web-images'
            element={<ProtectedRoute authorize={[UserRole.Admin]} component={<WebImages />} />}
          />
          <Route
            path='/admin/web-images/add'
            element={<ProtectedRoute authorize={[UserRole.Admin]} component={<AddWebImage />} />}
          />
          <Route
            path='/admin/users/add-employee'
            element={<ProtectedRoute authorize={[UserRole.Admin]} component={<AddEmployee />} />}
          />
          <Route
            path='/admin/orders'
            element={
              <ProtectedRoute
                authorize={[UserRole.Admin, UserRole.Employee]}
                component={<Orders />}
              />
            }
          />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
